.enterprise-container {
  background-color: #f8fcff;
}

.add-welcome-widgets-bundle {
  position: absolute;
  text-align: end;
  display: flex;
  top: 5rem;
}

.add-welcome-widgets-bundle svg {
  margin-left: 15px;
  padding: 2px 4px;
  cursor: pointer;
  border-radius: 2px;
  background-color: #1aaefa;
  color: #fff;
  border: 1px solid transparent;
}
