.distributors-container {
  background-color: #f8fcff;
}
.dashboard-main {
  height: 100%;
}

.menu-bar {
  display: flex;
  height: 100%;
  width: calc(100% - 10px);
  margin-left: auto;
}

.welcome {
  min-height: 100vh;
  width: 100%;
  background-color: #f8fcff;
}

.welcome-main {
  margin: 15px 0;
  padding: 0 10px;
}

.welcome-widgets {
  position: relative;
  top: 3rem;
  margin-right: 15px !important;
}

.expanded-filter {
  width: 300px;
  background-color: #fff;
  z-index: 10;
  box-shadow: 0px 5px 20px rgb(69 69 69 / 10%);
  border-radius: 3px;
  padding: 20px;
}

.welcome-widgets svg {
  margin-left: 15px;
  border: 1px solid #000;
  padding: 2px 4px;
  cursor: pointer;
  border-radius: 2px;
}

.welcome-widgets svg:hover,
.welcome-widgets svg:last-child {
  background-color: #1aaefa;
  color: #fff;
  border: 1px solid transparent;
  height: 30px !important;
  width: 30px !important;
  margin-left: 8px;
  padding: 2px 4px;
}

.expanded-search-select select {
  width: 75%;
}

.filter-header {
  font-size: 12px;
}

.switch {
  display: flex !important;
  align-items: center;
  margin-bottom: 10px;
}

.switch h5 {
  font-size: 14px;
  margin-left: 10px;
}

.date-field {
  border: 1px solid #b8e7ff !important;
  border-radius: 4px !important;
}
