.landing-page-container {
  background-color: #f8fcff;
}

.content {
  font-size: 36px;
  height: 20rem;
  text-align: center;
  vertical-align: top;
}

.MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 2px !important;
}
.manage-searchable-dropdown-name input {
  padding-right: 28px !important;
}

.documents-tab-panel {
  /* height: 478px; */
  height: calc(100vh - 280px) !important;
  overflow: auto;
}

.documents-tab-panel::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.documents-tab-panel::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.documents-tab-panel::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.documents-tab-panel::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.action-button-font {
  padding: 0.5rem 0.5rem !important;
  font-family: "Montserrat", sans-serif !important;
}
.bold-text {
  font-weight: bold !important;
  margin-bottom: 6px !important;
  margin-top: 0px !important;
  font-size: 16px !important;
  margin-left: -18px !important;
}
