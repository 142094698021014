.dashboard-main {
  height: 100%;
}

.welcome {
  min-height: 100vh;
  width: 100%;
  /* padding : 0px 20px;  */
  background-color: #f8fcff;
}

.welcome-main {
  margin: 15px 0;
  padding: 0 10px;
}

.welcome-widgets {
  position: relative;
  top: 0 !important;
}

.expanded-filter {
  width: 300px;
  background-color: #fff;
  z-index: 10;
  box-shadow: 0px 5px 20px rgb(69 69 69 / 10%);
  border-radius: 3px;
  /* padding: 20px; */
}

.filter-options {
  height: 20rem;
  overflow-y: auto;
  padding-right: 1rem;
}
.filter-options::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.filter-options::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.filter-options::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.filter-options::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.welcome-widgets svg {
  margin-left: 9px;
  border: 1px solid #000;
  padding: 2px 4px;
  cursor: pointer;
  border-radius: 2px;
}

.welcome-widgets svg:hover,
.welcome-widgets svg:last-child {
  background-color: #1aaefa;
  color: #fff;
  border: 1px solid transparent;
  height: 30px !important;
  width: 30px !important;
}

.expanded-search-select select {
  width: 75%;
}

.filter-header {
  font-size: 12px;
}

.switch {
  display: flex !important;
  align-items: center;
  margin-bottom: 10px;
}

.switch h5 {
  font-size: 14px;
  margin-left: 10px;
}
.filter-buttons-div {
  text-align: end;
  padding-top: 1rem;
}
.filter-buttons-div > .filter-buttons {
  margin-left: 1rem;
}
