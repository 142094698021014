.addedit-content-main > .accordion-title {
  font-weight: 700;
}

.button-container {
  text-align: end;
  margin: 1rem 1rem;
  display: flex;
  justify-content: flex-end;
  height: 47px;
}
.button-containers {
  justify-content: end;
  align-items: end;
}

.button-container > button {
  margin-left: 1rem;
  padding: 0.5rem;
}

.accordion-content-account-information {
  background-color: lightgray !important;
}

.content-checkbox-field {
  margin-top: 2.5rem;
  padding: 4px;
}

/* .extra-padding-input {
  margin-top: 1rem;
} */

.disabled,
.Mui-disabled {
  background-color: #e9ecef;
}
.padding-cus-pad {
  padding: 8px !important;
}

.rti--container.disabled {
  background-color: #e9ecef;
  padding: 18.1px !important;
  border: 1px solid #dddddd !important;
}

.no-preview {
  text-align: center;
}

.outline {
  text-transform: none !important;
}

#pdf-download {
  display: none;
}
.custom-sel-ect,
.custom-mar-gn {
  margin-left: 10px;
}
.custom-padding-cust {
  padding-right: 0 !important;
}
.customtext-width {
  width: 100% !important;
}
.customtextwidth {
  width: 100% !important;
}
.cus-cus-avl {
  margin-left: 2px;
  padding: 1px;
}
.cus-cus-fm .MuiCheckbox-root {
  padding: 7px;
}
.cus-cus-fma .MuiCheckbox-root {
  padding: 7.3px;
}
.cus-cus-fm {
  margin-left: 2px !important;
  margin-top: 26px !important;
  padding: 0px !important;
}
.top-pad {
  margin-top: 31px !important;
}
.border-cus-cus {
  border: 1px solid #b8e7ff !important;
}
.bs-cus {
  width: 100%;
  overflow-y: visible;
}
.border-cus-cus-disabled {
  border: 1px solid #dddddd !important;
}
.cus-cus-fma {
  padding: 0px !important;
  margin-left: 2px !important;
  margin-top: 27px !important;
}
.filename {
  margin-left: 125px;
  color: rgb(71, 135, 232);
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
}
.text-cus {
  max-width: 500px;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
}
.cus-cus-border-none .MuiOutlinedInput-notchedOutline {
  border-style: none !important;
}
.scrollable-input-content {
  overflow-y: auto; /* Add vertical scrollbar when content exceeds height */
  height: 100px !important;
  border: 0.5px solid #b8e7ff !important;
  resize: none;
}
.textarea-container {
  position: relative;
}

.textarea-container textarea {
  width: 100%;
  height: 100%;
  overflow-x: hidden !important;
}

.textarea-container textarea::placeholder {
  position: absolute;
  top: 40%;
  left: 2%;
  width: 100%;
  text-align: left;
  color: #797878;
  font-size: 14.5px;
}
.active-button {
  background-color: #1aaefa !important;
  color: white !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.dis-active-button {
  background-color: rgb(243, 238, 238) !important;
  color: black !important;
}
.delete-icon-color {
  color: red !important;
}
.add-content-icon-color {
  color: #1aaefa !important;
}
.filter-icon-alignment {
  display: flex;
  flex-direction: row-reverse;
  margin-top: -40px;
}
.filter-icon-subs {
  display: flex;
  flex-direction: row-reverse;
  justify-content: end;
  margin-right: -105px !important;
}
.active-record {
  background-color: #daecf8;
}
.sticky-top-element {
  position: sticky;
  top: 8px;
  z-index: 2;
  box-shadow: 0px 8px 0 #fff, 0px -8px 0 #fff;
}
