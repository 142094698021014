.toggle-switch {
  position: relative;

  /* width: 65px; */

  /* display: inline-block; */

  vertical-align: middle;

  -webkit-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  text-align: left;

  display: inline-flex;
  align-items: center;
  /* justify-content: space-around; */
  background: #999999;
  padding: 0px 1px;
  border-radius: 15px;
  border: 4px solid #999999;
}

.toggle-switch-checkbox {
  display: none;
}

.toggle-switch-1 {
  position: relative;

  /* width: 65px; */

  /* display: inline-block; */

  vertical-align: middle;

  -webkit-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  text-align: left;
  display: inline-flex;
  align-items: center;
  /* justify-content: space-around; */
  background: #09a852;
  padding: 0px 1px;
  border-radius: 15px;
  border: 4px solid #09a852;
}

.toggle-switch-inner {
  display: block;

  width: 200%;

  margin-left: -100%;

  transition: margin 0.3s ease-in 0s;
}

.toggle-switch-inner:before,
.toggle-switch-inner:after {
  display: block;

  float: left;

  width: 50%;

  height: 20px;

  padding: 0;

  line-height: 34px;

  font-size: 14px;

  color: white;

  font-weight: bold;

  box-sizing: border-box;
}

.toggle-switch-inner:before {
  content: "";

  background-color: transparent !important;

  /* border: 1px solid #083F86; */

  padding-left: 10px;

  /* background-color: #fff; */

  color: #fff;
}

.toggle-switch-disabled {
  background-color: #ddd;

  cursor: not-allowed;
}

.toggle-switch-disabled:before {
  background-color: #ddd;

  cursor: not-allowed;
}

.toggle-switch-inner:after {
  content: "";
  background-color: transparent !important;

  padding-right: 10px;

  /* background-color: #fff; */

  color: #fff;

  text-align: right;
}

.toggle-switch-switch {
  display: block;
  width: 6px;
  height: 6px;
  background: #fff;
  position: absolute;
  right: 1.6px !important;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.toggle-switch-switch-1 {
  display: block;
  width: 6px;
  height: 6px;
  /* margin: 5px; */
  background: #fff;
  position: absolute;
  /* top: 3.6px; */
  /* bottom: 5px; */
  right: 11px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.toggle-switch-label {
  height: 11px !important;
  width: 22px !important;

  display: flex !important;

  align-items: center;

  margin: 0;

  margin-left: 50px !important;

  /* width: 34px; */

  /* display: block; */

  overflow: hidden;

  /* border: 0 solid #bbb; */

  border-radius: 20px;

  /* width: 34px; */

  /* height: 18px; */

  border: 1.5px solid #fff;

  /* transform: rotate(180deg) !important; */
  position: absolute;
  left: -43px;
  top: 0px;
}

.toggle-switch-label-1 {
  height: 11px !important;
  width: 22px !important;

  display: flex !important;

  align-items: center;

  margin: 0;

  margin-left: 50px !important;

  /* display: block; */

  overflow: hidden;

  border-radius: 20px;

  /* width: 34px;

  height: 18px; */

  border: 1.5px solid #fff;

  /* transform: rotate(180deg) !important; */
  position: absolute;
  left: -43px;
  top: 0px;
}

.toggle-switch-label-1.enabled {
  cursor: pointer;
}

.toggle-switch-label.enabled {
  cursor: pointer;
}

.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
  margin-left: 0;
}

.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
  right: 0px;
}

.toggle-switch-root {
  display: flex;
  align-items: center;
}

.MuiSwitch-track {
  display: none !important;
}

.MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked {
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
}

.MuiButtonBase-root.MuiSwitch-switchBase {
  padding: 9px 0 !important;
}
