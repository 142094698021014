.MuiAutocomplete-hasPopupIcon.css-gcwvw8-MuiAutocomplete-root
  .MuiOutlinedInput-root,
.MuiAutocomplete-hasClearIcon.css-gcwvw8-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  height: 37px !important;
  /* margin-top: 24px; */
  background: white;
}

#serachable-drop {
  padding: 0 !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
}

#serachable-drop:disabled {
  background-color: #e9ecef !important;
}
