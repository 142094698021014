.catalog-container {
  background-color: #f8fcff;
}

.add-welcome-widgets-catalog {
  position: absolute;
  text-align: end;
  display: flex;
  top: 5rem;
}

.add-welcome-widgets-catalog svg {
  margin-left: 9px;
  padding: 2px 4px;
  cursor: pointer;
  border-radius: 2px;
  background-color: #1aaefa;
  color: #fff;
  border: 1px solid transparent;
  height: 30px !important;
  width: 30px !important;
}
