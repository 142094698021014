#modal-modal-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

.reason-div {
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #b8e7ff;
  border-radius: 3px;
  background: #fafdff;
}

.reason-div > .MuiFormControl-root.MuiTextField-root {
  height: 100%;
}

.action-btn-div {
  text-align: center;
}

.MuiButton-outlined,
.MuiButton-contained {
  text-transform: none !important;
}
.action-popup-share {
  display: flex !important;
  justify-content: flex-end !important;
}
