.grid-left {
  background: linear-gradient(180deg, #fafafa 10%, #e6e6e6 100%) !important;
}

.grid-left p {
  max-width: 282px;
  padding-left: 15px;
  margin-left: 10px;
  color: #454545 !important;
  font-size: 22px;
  font-weight: 700;
  margin-top: 10px;
}

#okta-sign-in .o-form-button-bar .button {
  background-color: #1aaefa;
  border: 1px solid #1aaefa;
  color: #fff;
  height: 20% !important;
  cursor: pointer;
}

/* #okta-sign-in.button-wide, #okta-sign-in .button-wide.button-primary */

.login-form-content {
  margin: 10px 48.5px;
  padding: 10px 42px;
}

.login-form-content.right {
  text-align: end;
}

.login-form {
  background-color: #fff;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
}

.main-grid {
  box-shadow: 0px 50px 50px rgb(51 51 51 / 10%);
  overflow: hidden;
}

.myContainer {
  background: linear-gradient(
    to right,
    #f8fcff 0%,
    #f8fcff 40%,
    #f8fcff 43%,
    #effdfa 40%,
    #effdfa 100%
  );
  border-radius: 8px;
}
.myContainer-link {
  background: #f8fcff 40%;
}

.main-grid-container {
  height: 100vh;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.main-grid-containers {
  height: 300vh !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.link.help,
.js-help-link {
  display: none !important;
}

#okta-sign-in .button,
#okta-sign-in .button.link-button {
  margin-top: 20px !important;
}

.auth-content {
  height: 500px;
  overflow-y: scroll;
}

.auth-content::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.auth-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.auth-content::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.auth-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.activate-form {
  display: flex;
  width: inherit;
}
.signup-typo-cus {
  margin: 10px;
}
.menu-item-cus.selected {
  background-color: #f0f0f0;
  font-weight: bold;
  color: #1aaefa;
}
.signup-button {
  width: 100px;
  margin: 20px !important;
  color: black !important;
  font-weight: 500 !important;
}
.signup-buttons {
  width: 100px;
  margin: 20px !important;
  font-weight: 500 !important;
  background: linear-gradient(#007dc1, #0073b2) !important;
  background-color: #1aaefa !important;
  border-bottom-color: #00456a !important;
  border-radius: 3px !important;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15),
    inset 0 1px 0 0 hsla(0, 0%, 100%, 0.1) !important;
  color: #fff !important;
  cursor: pointer;
  font-family: montserrat, Arial, Helvetica, sans-serif;
  font-size: 15px !important;
  height: 40px !important;
  letter-spacing: 1px !important;
}
.signup-buttons-disabled {
  width: 100px;
  margin: 20px !important;
  font-weight: 500 !important;
  background-color: #d9dfe3 !important;
  border-bottom-color: #81888c !important;
  border-radius: 3px !important;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15),
    inset 0 1px 0 0 hsla(0, 0%, 100%, 0.1) !important;
  color: #fff !important;
  cursor: pointer;
  font-family: montserrat, Arial, Helvetica, sans-serif;
  font-size: 15px !important;
  height: 40px !important;
  letter-spacing: 1px !important;
}
.signup-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.signUp-sub-main {
  margin: 30px;
  width: 400px;
}
.sign-up-text-cus {
  width: 350px;
  margin-left: 298px;
  margin-top: 10px;
}

.signup-header {
  display: flex;
}
.main-div-cus {
  display: flex;
}
.menu-item-cus {
  height: 50px !important;
  font-size: 16px !important;
}
.cus-divider {
  margin-top: 90px !important;
}
.inpur-radio {
  margin-top: 20px !important;
  font-weight: bold !important;
}
.radio-buttons-align {
  display: flex;
  flex-direction: column;
}
.sign-up-cus-menu {
  margin-top: 40px !important;
}
.signup-div-cus {
  margin: 30px;
  overflow-y: auto;
  height: 450px;
  width: 95% !important;
  padding-top: 30px;
  padding-bottom: 0px !important;
}
.sign-div-cus {
  margin-left: 10px !important;
}
.div-flex-cus {
  display: flex;
  flex-direction: row;
  height: 35px;
  width: 361px;
  margin-top: 50px;
}
.sign-custom {
  margin-left: 20px;
}
.div-radio-cus {
  margin-left: 10px;
  margin-right: 20px;
}
.div-radio-cuss {
  margin-left: 2px;
  margin-right: 20px;
  margin-top: -2px;
}
.cus-box-width {
  width: 190px !important;
}
.cus-icon-align {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-left: -95px;
}
.align-button-cus {
  display: flex;
  flex-direction: row-reverse;
  margin-left: 446px;
}
.icon-margin {
  margin: 5px;
}
.paper-cus {
  width: 1000px;
  height: 600px;
}

.share-container {
  align-items: center;
  justify-content: flex-end;
}

.share-form {
  background-color: #fff;
  display: flex;
  justify-content: center;
}

.invalid-link {
  color: red;
}
.custom-info-icon {
  margin-top: -5px !important;
  margin-bottom: 0px !important;
  cursor: pointer !important;
}
.custom-info-icon-activate-user {
  margin-top: 10px !important;
  margin-bottom: 0px !important;
}
.custom-icon-info {
  font-size: 11px;
  width: 160px;
  margin-top: 5px !important;
  margin-bottom: -10px !important;
  margin-left: 10px !important;
  position: relative;
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  padding: 0px !important;
  text-align: left;
}
.custom-icon-infos {
  font-size: 11px;
  width: 160px;
  margin-top: 5px !important;
  margin-bottom: -10px !important;
  margin-left: 10px !important;
  position: relative;
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  padding: 0px !important;
  text-align: left;
}
.invalid-links {
  width: 600px !important;
}
.container-alignment {
  display: flex;
}
.type-align {
  display: flex;
  justify-content: center;
  margin-top: 20px !important;
}
.cus-align-div {
  width: 400px;
  height: 200px;
  background: white;
}
.cus-grid-align {
  display: flex;
  justify-content: center;
  width: 90%;
}
.cus-submit-align {
  width: 90%;
}

.custom-icon-infos p {
  margin-bottom: 10px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 2px;
}

.custom-icon-infos ul {
  list-style-type: disc;
  padding-left: 20px;
}

.custom-icon-infos li {
  margin-bottom: 5px;
  padding-bottom: 2px;
}
.media {
  display: none;
}
@media screen and (max-width: 600px) {
  .media {
    display: block;
  }
}
.reset-heading {
  font-weight: bold !important;
}
.help-text-cus-position {
  margin-left: 26px;
  margin-top: -30px;
  margin-bottom: 20px;
}
.help-text-color {
  color: #0073b2;
  font-weight: 600 !important;
}
.help-text-width {
  width: fit-content !important;
}
.custom-info-icon-datatable {
  margin-top: 4.5px !important;
  margin-bottom: 0px !important;
  cursor: pointer !important;
  font-size: 20px !important;
}
