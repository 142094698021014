.approval-container {
  margin: 2rem;
  background: linear-gradient(0deg, #f8fcff, #f8fcff), #fbb716;
}

.MuiBreadcrumbs-li {
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
}

.approval-page-title {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
}

.approval-basic-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 2rem;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
}

.create-distributor-container {
  background-color: white;
}

.modal-header {
  padding: 5px 15px !important;
}

.blue-field-section {
  background-color: #f8fcff;
}

.modal-body::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.modal-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.modal-body::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.modal-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

input {
  width: 100%;
}

.modal-body label {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
}

h5 {
  margin-bottom: 0;
}

.add-item h5 {
  margin-top: 31px;
  cursor: pointer;
  color: #1aaefa;
  font-size: 14px;
  font-weight: 500;
}

.parent-div {
  background-color: white;
  padding: 2rem;
  margin: 0 2rem;
}

.detail-item {
  width: 100%;
}

.modal-footer button:first-child {
  margin-right: 20px;
  font-size: 14px;
}

/* .modal-footer button:last-child {
    background-color: #1aaefa;
    color: #fff;
  } */

.modal-footer button:last-child:hover {
  box-shadow: 0 5px 10px rgba(26, 174, 250, 0.5);
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
option.form-control,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="select"],
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
}

input::placeholder {
  font-size: 13px !important;
  font-weight: 500;
}

.hide-switch {
  height: 15px;
  width: 25px;
  background: transparent;
  position: absolute;
  z-index: 20;
  bottom: 50px;
  left: 25px;
}

.select-user {
  font-size: 14px;
}

.primary-email-url {
  display: block;
  margin-top: 4px;
  word-break: break-all;
  font-size: 14px;
}

.modal-buttons > .outline {
  margin-right: 1rem;
}

.date-picker-matui input {
  padding: 7.5px !important;
}

.date-picker-matui input:disabled {
  background-color: #e9ecef !important;
}

.serachable-dropdown:disabled {
  background-color: #e9ecef !important;
}

.serachable-dropdown .Mui-disabled {
  background-color: #e9ecef !important;
}

button:focus {
  outline: 0px dotted !important;
  outline: 0px auto -webkit-focus-ring-color !important;
}

.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd {
  padding: 0.5rem;
  border: none !important;
}

.basic-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.cancel,
.save {
  background-color: #1aaefa;
  padding: 8px 20px;
  border-radius: 5px;
  color: #fff;
  text-decoration: none;
  border: transparent;
  font-size: 12px;
  margin-right: 5px;
  cursor: pointer;
}

.approve {
  background-color: #09a852;
  padding: 8px 20px;
  border-radius: 5px;
  color: #fff;
  text-decoration: none;
  border: transparent;
  font-size: 12px;
  margin-right: 5px;
  cursor: pointer;
}

.reject {
  background-color: #ed4949;
  padding: 8px 20px;
  border-radius: 5px;
  color: #fff;
  text-decoration: none;
  border: transparent;
  font-size: 12px;
  margin-right: 5px;
  cursor: pointer;
}

.approval-basic-details h5 {
  font-size: 14px;
}

.base-value .MuiFormControl-root {
  width: 100% !important;
}

#baseValue {
  padding: 7px !important;
}

.inner-container {
  padding: 7px !important;
}
.inner-containers {
  padding: 5.5px !important;
  height: 1px !important;
}
.cuss-cus-fm {
  margin-left: 2px !important;
  margin-top: 0px !important;
  padding: 0px !important;
  height: 40px;
}
.inner-containerss {
  padding: 4px !important;
}
.container-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-btn-container {
  text-align: inherit;
}

.delete-btn {
  padding: 30px !important;
}

#subscriptionFrequency input[type="text"] {
  border: none !important;
}

#subscriptionFrequency input[type="text"]:focus {
  box-shadow: none !important;
}
.cus-height-createOutlinedIcon {
  height: 1rem !important;
}
.add-in-checkbox {
  margin-top: -4px !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.content-name-alignment {
  display: flex;
  align-items: center;
}

.content-name-alignment label {
  width: 200px;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .content-name-alignment label {
    width: auto;
  }
}
.hr-style-subs {
  width: 100%;
  color: white;
  border: none;
  border-top: 5px solid;
  height: 4px;
}
