@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.cloud-container {
  padding-top: 199px;
  position: relative;
}

.cloud-container-position {
  padding-left: 25px;
}
.logo-login-position {
  padding: 22px !important;
}

.cloud-container img {
  transform: translateY(40px);
}

.cl1 {
  height: 100px;
  width: 100px;
  background: #f6fafd;
  position: absolute;
  bottom: -39px;
  border-radius: 100%;
  left: -18px;
}

.cl2 {
  height: 80px;
  width: 80px;
  background: #f6fafd;
  position: absolute;
  bottom: -40px;
  border-radius: 100%;
  left: 67px;
}

.cl3 {
  height: 90px;
  width: 90px;
  background: #f6fafd;
  position: absolute;
  bottom: -37px;
  border-radius: 100%;
  left: 116px;
}
.cl4 {
  height: 100px;
  width: 110px;
  background: #f6fafd;
  position: absolute;
  bottom: -53px;
  border-radius: 100%;
  left: 203px;
}

.cl5 {
  height: 80px;
  width: 80px;
  background: #f6fafd;
  position: absolute;
  bottom: -39px;
  border-radius: 100%;
  left: 281px;
}

.social-icons img:nth-child(even) {
  margin: 0 20px;
}

.col-details {
  display: flex !important;
  align-items: center;
}

.col-details-heading {
  font-size: 14px;
  margin-bottom: 0 !important;
}

.col-details-heading-fname {
  width: 110px;
}

.col-details-heading-usertype {
  width: 85px;
}

.col-details-heading-lname {
  width: 90px;
}

.col-details-heading-email {
  width: 50px;
}

.col-details-heading-userrole {
  width: 230px;
}

.col-details-heading-status {
  width: 60px;
}

.action-item-icons {
  display: flex !important;
  flex-wrap: nowrap !important;
  justify-content: flex-end !important;
  align-items: center !important;
  /* padding-right: 20px !important; */
}

.action-item-icons > div {
  padding: 0 10px !important;
  margin: 0 !important;
}

.resp-table-subs {
  overflow-x: auto !important;
}

.resp-table-internal-user {
  padding: 5px;
}

.resp-table {
  max-height: calc(100vh - 260px) !important;
  overflow-x: auto !important;
  position: relative !important;
  z-index: 0;
}
.resp-table-dist-content {
  max-height: calc(100vh - 460px) !important;
  overflow-x: auto !important;
  position: relative !important;
  z-index: 0;
}
.resp-table-managedoc {
  max-height: calc(100vh - 420px) !important;
  overflow-x: auto !important;
  position: relative !important;
  z-index: 0;
}
.resp-table-dist-internal {
  max-height: calc(100vh - 410px) !important;
  overflow-x: auto !important;
  position: relative !important;
  z-index: 0;
}

.resp-data-table {
  max-height: calc(100vh - 250px);
}

.resp-admin-data-table {
  max-height: calc(100vh - 275px);
}

.internal-user-action-item {
  transform: translateX(4px);
}

@media (max-width: 1180px) {
  .action-item-icons {
    margin-left: 10px !important;
  }
  td > a {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 130px !important;
    overflow: hidden;
  }
  .custom-modal {
    width: 700px !important;
  }
  td {
    white-space: nowrap;
  }
  .user-role-td {
    transform: translateX(-20px);
  }
}

@media (max-width: 769px) {
  .cloud-container {
    padding-top: 180px;
  }
  td > a {
    width: 120px !important;
  }
  .toggle-switch-td {
    transform: translateX(-5px);
  }
}

.user-role-td {
  transform: translateX(2px);
}

.role-list-table th,
.role-list-table td {
  padding: 0 !important;
}

.role-list-table th svg,
.role-list-table td svg {
  font-size: 16px !important;
}

.welcome-widget-main {
  position: absolute;
  top: 67px;
  right: 32px;
  z-index: 1;
}

.associate-overlay {
  background: transparent;
  position: absolute;
  top: -5px;
  width: 30px;
  z-index: 100;
  height: 21px;
}

.welcome {
  min-height: 320px !important;
}

.welcome::-webkit-scrollbar-track {
  background-color: darkgrey !important;
}

.distributor-container .resp-admin-data-table {
  max-height: calc(100vh - 310px);
}

.distributor-container .resp-data-table {
  max-height: calc(100vh - 310px);
}

.addedit-content-main .resp-data-table {
  max-height: calc(100vh - 437px);
}

.my-tabs {
  padding: 0 16px !important;
  text-transform: none !important;
  font-size: 16px !important;
}
.Toastify {
  z-index: 999;
}

@media only screen and (max-width: 750px) {
  .cl1,
  .cl2,
  .cl3,
  .cl4,
  .cl5 {
    display: none !important;
  }

  .cloud-container img {
    width: 60% !important;
    transform: translateY(1px) !important;
  }

  .login-form-content {
    margin: 10px !important;
    padding: 10px !important;
  }

  #custom-signin {
    margin: 10px !important;
    padding: 10px !important;
  }

  .login-para {
    font-size: 14px !important;
  }

  .side-logo {
    width: 100% !important;
  }

  .app-container {
    width: 100% !important;
  }
  .myContainer .grid-left {
    flex-basis: 30% !important;
    max-width: 30% !important;
  }

  .myContainer .login-form {
    flex-basis: 70% !important;
    max-width: 70% !important;
  }
}
