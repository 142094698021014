.viewer-header {
  display: flex;
  -webkit-box-pack: end;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  z-index: 1;
  padding: 0px 10px;
  background-color: rgb(82, 150, 216);
  font-size: 16px;
  min-height: 50px;
}

.viewer-file-name {
  flex: 1 1 0%;
  text-align: left;
  color: rgb(255, 255, 255);
  font-weight: bold;
  margin: 0px 10px;
  overflow: hidden;
}

.viewer-navigation {
  display: flex;
}

.viewer-navigation-button {
  background-color: white !important;
  margin-left: 1rem !important;
}

.viewer-container {
  width: 980px;
  background-color: lightgray;
  position: absolute;
  height: 1360px;
  padding: 1rem;
  max-width: unset !important;
}

.xfdlcheckbox-req {
  border: 1px solid red;
}

.xfdlcheckbox-filled {
  border: 1px solid black;
}

.xfdlcheckbox-empty {
  border: 1px solid blue;
}

.share-text-box-xfdl .MuiInputBase-root {
  border-radius: 0 !important;
}
