.content-container {
  background-color: #f8fcff;
}

.top-right-actions {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 1.5rem;
}
.top-right-actions button {
  margin-left: 10px;
  margin-right: 10px;
}
.top-center-actions {
  display: flex;
  justify-content: center;
  margin: 1rem 1.5rem;
}

.add-welcome-widgets-content {
  position: absolute;
  text-align: end;
  display: flex;
  top: 5rem;
}

.add-welcome-widgets-content svg {
  margin-left: 9px;
  padding: 2px 4px;
  cursor: pointer;
  border-radius: 2px;
  background-color: #1aaefa;
  color: #fff;
  border: 1px solid transparent;
  height: 30px !important;
  width: 30px !important;
}
