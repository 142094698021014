.create-distributor-container {
  background-color: white;
}

.modal-header {
  padding: 5px 15px !important;
}

.blue-field-section {
  background-color: #f8fcff;
}
.recurring-check-cus {
  margin-top: -37px !important;
}
.modal-body::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.modal-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.modal-body::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.modal-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

input {
  width: 100%;
}

.modal-body label {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
}

h5 {
  margin-bottom: 0;
}

.add-item h5 {
  margin-top: 31px;
  cursor: pointer;
  color: #1aaefa;
  font-size: 14px;
  font-weight: 500;
}

.modal-footer button:first-child {
  margin-right: 20px;
  font-size: 14px;
}

/* .modal-footer button:last-child {
    background-color: #1aaefa;
    color: #fff;
  } */

.modal-footer button:last-child:hover {
  box-shadow: 0 5px 10px rgba(26, 174, 250, 0.5);
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
option.form-control,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="select"],
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
}

input::placeholder {
  font-size: 13px !important;
  font-weight: 500;
}

.hide-switch {
  height: 15px;
  width: 25px;
  background: transparent;
  position: absolute;
  z-index: 20;
  bottom: 50px;
  left: 25px;
}

.select-user {
  font-size: 14px;
}

.primary-email-url {
  display: block;
  margin-top: 4px;
  word-break: break-all;
  font-size: 14px;
}

.outline {
  margin-right: 1rem !important;
}

.date-picker-matui input {
  padding: 7.5px !important;
}

.date-picker-matui input:disabled {
  background-color: #e9ecef !important;
}

.serachable-dropdown:disabled {
  background-color: #e9ecef !important;
}

.serachable-dropdown .Mui-disabled {
  background-color: #e9ecef !important;
}

button:focus {
  outline: 0px dotted !important;
  outline: 0px auto -webkit-focus-ring-color !important;
}

.css-1mz5kyr {
  border-bottom: none !important;
}

.accordion-ent {
  margin-top: 20px;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded:first-of-type {
  margin-top: 20px !important;
}

.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  color: #1aaefa !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.MuiOutlinedInput-root {
  height: 37px !important;
  padding: 0 !important;
  width: 250px !important;
}

.add-association {
  color: #848484;
  font-weight: 600 !important;
  top: 20px !important;
}
.add-association svg {
  padding: 2px 4px;
  cursor: pointer;
  border-radius: 2px;
  background-color: #1aaefa;
  color: #fff;
  border: 1px solid transparent;
  height: 35px;
  width: 35px;
  position: relative;
  top: 10px;
  left: 10px;
}

.delete-icon {
  color: #1aaefa;
  cursor: pointer;
  margin-top: 30px !important;
}

#enterpriseGroupIdDropdown {
  padding: 2px 25px 6px 9px !important;
  transform: translateY(2px);
}

#startDate {
  padding: 7px !important;
}

.action-btn-modal {
  text-align: center;
  padding-left: 10px;
}

.association-action-btns {
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  margin-top: 14px !important;
}

.send-icon-svg {
  height: 37px !important;
  width: 37px !important;
  margin-right: 10px;
  padding: 2px 4px;
  cursor: pointer;
  border-radius: 3px;
  background-color: #1aaefa;
  color: #fff;
  border: 1px solid transparent;
  position: relative;
  top: 10px;
  left: 10px;
}

.delete-icon-svg {
  height: 37px !important;
  width: 37px !important;
  margin-right: 10px;
  border-radius: 3px;
  color: #fff;
  border: 1px solid transparent;
  position: relative;
  top: 10px;
  left: 10px;
}

.enterpriseGroupIdDropdown {
  width: 200px !important;
}
.enterpriseGroupIdDropdown
  .MuiInputBase-fullWidth.MuiInputBase-formControl.MuiOutlinedInput-root {
  height: 37px !important;
  padding: 0 !important;
  width: 100% !important;
}

.disabledBtnState {
  cursor: unset !important;
  background-color: #83b7d0 !important;
}

.membership-card {
  background-color: #eff8ff !important;
  padding: 10px !important;
}
.buy-btn {
  width: 100% !important;
}

.membership-card .items {
  padding-bottom: 10px !important;
}

.datepicker-assoc input[type="tel"] {
  border: none !important;
}

@media only screen and (min-width: 1120px) {
  .search-dropdown-existing .MuiAutocomplete-root {
    padding: 0px !important;
  }
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
    .MuiOutlinedInput-root {
    padding-right: 32px !important;
  }
}

#distributorGroupDropDown {
  padding-right: 32px !important;
  border: none !important;
}
.searchab-cus {
  width: 124% !important;
}

.search-dropdown-existing
  .MuiAutocomplete-root
  .MuiFormControl-root
  .MuiInputBase-root
  .MuiOutlinedInput-root {
  padding: 6px !important;
}

.expired-status-text {
  display: inline-flex;
  color: red;
  position: relative;
  text-transform: uppercase;
  top: 10px;
  font-size: 15px;
  padding: 0 5px;
}
.modal-shadow {
  background-color: rgb(0 0 0 / 1%);
}
.modal-shadow > .MuiBox-root {
  border: 1px solid lightgrey;
  box-shadow: none;
  border-radius: 6px;
}
.custom-grid-spacing {
  background-color: white !important;
  height: 11px !important;
}
.cus-height-createOutlinedIcon {
  height: 1rem !important;
}
.subscription-popup-design {
  background-color: lightgrey;
  /* margin-left: -16px;
  margin-right: -16px;
  margin-top: -16px; */
  height: 60px;
}
.subscription-content-modal-detail {
  background-color: white;
  border-bottom: 1px solid lightgrey;
}
& .MuiPaper-root.MuiAccordion-root.Mui-expanded:first-of-type {
  margin-top: 0px !important;
}

.accordion-class-1 {
  background-color: white;
  border-bottom: 1px solid lightgrey;
  padding-top: 12px;
  padding-bottom: 2px;
  padding-left: 16px;
  height: 48px;
}
.accordion-class-2 {
  margin: 0 !important;
  box-shadow: none !important;
}
.accordion-class-3 {
  padding: 0px 0px 0px 0px !important;
}
.accordion-class-4 {
  border-bottom: 1px solid lightgrey !important;
  height: 48px !important;
  min-height: 48px !important;
}
.accordion-class-5 {
  padding-left: 48px !important;
}
.accordion-class-6 {
  padding-top: 12px;
  height: 48px;
}
.accordion-class-7 {
  padding-left: 32px !important;
}

.accordion-subs-modal {
  background-color: white !important;
  border-bottom: 1px solid lightgrey !important;
}
.profile-photos {
  height: 40px;
  width: 40px;
}

.profile-photos:hover {
  cursor: pointer;
}

.profile-photos > .profile {
  background-color: white;
  padding: 0.5rem;
  margin: 0.25rem 0;
  border-radius: 50%;
}
.profile-photos > .brandImage {
  height: 40px;
  width: 40px;
  padding: 0 !important;
}
.stop-button-enterprise-style {
  margin-top: 30px !important;
  background-color: #f73838f7 !important;
  color: white !important;
  width: 150px !important;
}

.subscription-action {
  padding: 0.25rem 1rem !important;
  font-weight: 700 !important;
  width: 160px;
  font-size: 10px !important;
  font-family: "Montserrat", sans-serif !important;
}

.conatined-green {
  background-color: #09a852 !important;
}

.conatined-red {
  background-color: #ed4949 !important;
}
.accordion-content-scroll {
  overflow-y: auto !important;
  max-height: 250px !important;
}
.logotextval-align {
  display: flex;
  justify-content: center;
}
