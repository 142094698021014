.disable-file-uploader {
  border: 1px solid #ced4da;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  height: 37px;
  /* width: 327px; */
  width: 100%;
  background: #e9ecef;
}

.file-uploader {
  border: 1px solid #b8e7ff !important;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  height: 37px;
  /* width: 327px; */
  width: 100%;
  background: #e9ecef;
}

.file-upload-label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.file-div {
  display: flex;
  width: 85%;
}

.file-div > button {
  justify-content: left;
  font-size: 12px;
}

/* .disabled {
  background-color: #e9ecef;
} */

.file-name {
  width: 100%;
  margin-left: 0.5rem;
  text-align: left;
}

.attachment-icon {
  width: 100%;
}
