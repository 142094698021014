.modal-header {
  padding: 5px 15px !important;
  margin-left: 18px !important;
  margin-right: 18px !important;
  margin-top: 8px !important;
}
.modal-header-left {
  margin-top: 1px !important;
  margin-left: 10px !important;
}
.modal-header-lefts {
  margin-top: 3px !important;
}

input {
  width: 100%;
}

.modal-body label {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
}
.modal-body {
  margin-left: 18px !important;
  margin-right: 18px !important;
  margin-bottom: -4px !important;
}

h5 {
  margin-bottom: 0;
}

.add-item h5 {
  margin-top: 38px !important;
  cursor: pointer;
  color: #1aaefa;
  font-size: 14px;
  font-weight: 500;
}

.modal-footer button:first-child {
  margin-right: 20px;
  font-size: 14px;
}
.modal-footer {
  margin-left: 18px !important;
  margin-right: 40px !important;
}

.modal-footer button:last-child:hover {
  box-shadow: 0 5px 10px rgba(26, 174, 250, 0.5);
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
option.form-control,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="select"],
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
}

input::placeholder {
  font-size: 13px !important;
  font-weight: 500;
}

.hide-switch {
  height: 15px;
  width: 25px;
  background: transparent;
  position: absolute;
  z-index: 20;
  bottom: 50px;
  left: 25px;
}

.select-user {
  font-size: 14px;
}

.primary-email-url {
  display: block;
  margin-top: 4px;
  word-break: break-all;
  font-size: 14px;
}
.tab-heading-text {
  text-transform: none !important;
}
.cus-left-align-role {
  margin-left: 2px !important;
}
