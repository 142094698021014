.addedit-content-main > .accordion-title {
  font-weight: 700;
}

.button-container {
  text-align: end;
  margin: 1rem 1rem;
  display: flex;
  justify-content: flex-end;
}

.button-container > button {
  margin-left: 1rem;
  padding: 0.5rem;
}

.accordion-content-account-information {
  background-color: lightgray !important;
}

.content-checkbox-field {
  margin-top: 2.5rem;
  padding: 0 10px;
}

/* .extra-padding-input {
  margin-top: 1rem;
} */

.disabled,
.Mui-disabled {
  background-color: #e9ecef;
}

.rti--container.disabled {
  background-color: #e9ecef;
}

.no-preview {
  text-align: center;
}

.outline {
  text-transform: none !important;
}

#pdf-download {
  display: none;
}

#baseValue,
#oneTimeCost,
#actualValue {
  padding: 7px !important;
}

#subscriptions input[type="text"] {
  border: none !important;
}

.input-container {
  position: relative;
}

.scrollable-input-bundle {
  overflow-y: auto; /* Add vertical scrollbar when content exceeds height */
  height: 100px !important;
}

.textarea-container {
  position: relative;
}

.textarea-container textarea {
  width: 100%;
  height: 100%;
  overflow-x: hidden !important;
}

.textarea-container textarea::placeholder {
  position: absolute;
  top: 40%;
  left: 2%;
  width: 100%;
  text-align: left;
  color: #797878;
  font-size: 14.5px;
}
.active-button {
  background-color: #1aaefa !important;
  color: white !important;
}
.delete-icon-color {
  color: red !important;
}
.add-content-icon-color {
  color: #1aaefa !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.filter-icon-alignment {
  display: flex;
  flex-direction: row-reverse;
  margin-top: -40px;
}

.sticky-top-element {
  position: sticky;
  top: 8px;
  z-index: 2;
  box-shadow: 0px 8px 0 #fff, 0px -8px 0 #fff;
}
