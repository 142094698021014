#modal-modal-title {
  font-size: larger;
  font-weight: 700;
}

.reason-div {
  text-align: center;
  width: 100%;
}

.action-btn-div {
  text-align: end;
}

.MuiButton-outlined,
.MuiButton-contained {
  text-transform: none !important;
}
