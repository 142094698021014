.my-modal-body {
  margin: 0 1rem !important;
}

.my-modal-body div {
  padding: 2rem 0;
}

.my-modal-footer {
  justify-content: space-between !important;
  padding: 1rem !important;
}

.file-upload-modal > .modal-body.file-upload-body {
  height: 300px;
  overflow-y: scroll;
}

.file-upload-modal > .modal-body.tabular-body {
  height: 200px;
  overflow-y: hidden;
}

.file-upload-modal > .modal-body::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.file-upload-modal > .modal-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.file-upload-modal > .modal-body::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.file-upload-modal > .modal-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.uploaded-file {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}
.text-align-center-upload-modal {
  display: contents !important;
}

.expanded-options {
  width: 150px;
  background-color: #fff;
  z-index: 10;
  box-shadow: 0px 5px 20px rgb(69 69 69 / 10%);
  border-radius: 3px;
}

.button-text {
  /* background-color: #04aa6d !important; Green */
  background-color: #1aaefa !important;
  border: 1px solid white !important;
  color: white !important;
  padding: 10px 2px !important;
  text-align: center;
  font-size: 9px !important;
  width: inherit !important;
}
.p-mass-upload-content-margin {
  margin-top: 20px !important;
  margin-bottom: 10px !important;
}
.modal-footer-align {
  display: flex !important;
  justify-content: center !important;
}
.modal-switch-align {
  display: flex !important;
  justify-content: center !important;
  margin: 6px !important;
}
.mass-upload-p-align {
  display: flex !important;
  justify-content: center !important;
  width: 93% !important;
  margin-left: 20px !important;
}
