* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.resp-table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
  overflow-x: auto;
}

.resp-table::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: #f5f5f5;
  overflow-x: auto;
}

.resp-table::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #b0adad;
  overflow-x: auto;
}

.resp-table-dist-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
  overflow-x: auto;
}

.resp-table-dist-content::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: #f5f5f5;
  overflow-x: auto;
}

.resp-table-dist-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #b0adad;
  overflow-x: auto;
}

.resp-table-managedoc::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
  overflow-x: auto;
}

.resp-table-managedoc::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: #f5f5f5;
  overflow-x: auto;
}

.resp-table-managedoc::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #b0adad;
  overflow-x: auto;
}

.resp-table-dist-internal::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
  overflow-x: auto;
}

.resp-table-dist-internal::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: #f5f5f5;
  overflow-x: auto;
}

.resp-table-dist-internal::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #b0adad;
  overflow-x: auto;
}

/* global */

p {
  margin-bottom: 0 !important;
}

/* Variables */
:root {
  --text-primary: #333;
}

/* Varibale Classes */

.mw-400 {
  max-width: 400px;
}

body {
  font-family: "Montserrat", sans-serif !important;
  outline: 0 !important;
  overflow: auto;
}

.main-container {
  display: flex;
  background-color: #f8fcff !important;
}
.app-container {
  height: 100%;
  width: calc(100% - 50px);
  margin-left: auto;
}

.content-left-main {
  background-color: rgb(53, 53, 53);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}

.content-left-main img {
  width: 266px;
}

.content-left-main h3 {
  max-width: 350px;
  color: #fff;
  font-weight: 700;
  font-size: 22px;
}

.content-right-main {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.username > span,
.password > span {
  position: absolute;
  top: 29px;
  right: 10px;
  cursor: pointer;
}

.create-account {
  color: #333;
  font-size: 14px;
  font-weight: 500;
}

.content-right-heading h6 {
  color: #333;
  font-size: 16px;
  font-weight: 500;
}

.forget-password p {
  font-size: 14px;
  font-weight: 500;
  color: #ddd;
}

.table-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0;
  padding: 0 10px;
}

.table-pagination button {
  min-width: 27px;
  height: 28px;
}

.table-pagination ul button {
  border: 1px solid;
  border-radius: 3px;
  margin: 2px;
}

/* .table-pagination ul button:first-child , .table-pagination ul button:last-child {

} */

.table-pagination ul button.Mui-selected {
  background-color: #1aaefa;
  color: #fff;
}

.table-pagination ul button.Mui-selected:hover {
  background-color: #1aaefa;
}

/* OKTA */

.auth-footer ul {
  display: block !important;
}

.auth-footer .link.help,
.auth-footer .link.js-help-link {
  display: none;
}

#okta-sign-in ol,
#okta-sign-in ul {
  text-align: center;
}

#okta-sign-in {
  background-color: #fff !important;
  border: 0 solid;
  margin: 2px auto 8px !important;
}

.link.help.js-back {
  display: block !important;
  text-align: center;
}

.okta-sign-in-header {
  display: none;
}

.primary-auth .o-form-content h2 {
  display: none;
}

.App {
  min-height: 100vh;
}

#okta-sign-in.auth-container.main-container {
  display: none;
}

.email-button {
  background-color: #1aaefa !important;
  border: 1px solid #1aaefa !important;
  color: #fff !important;
  height: 20% !important;
  cursor: pointer !important;
}

.o-form-input.o-form-has-errors {
  position: relative !important;
}

.okta-form-input-error {
  position: absolute !important;
  top: 40px;
}

#okta-sign-in .o-form .custom-checkbox label {
  margin-top: 12px !important;
}

.okta-form-label.o-form-label {
  margin-top: 10px !important;
}

#okta-sign-in .infobox {
  margin-bottom: 0 !important;
  padding: 5px 41px !important;
}

#okta-sign-in .auth-content .help-links li {
  margin-top: 0 !important;
}

.MuiTooltip-popper > div {
  background-color: #333333 !important;
  padding: 5px 10px;
}

/* .tab-panel div div {
  padding: 0 !important;
} */

.file-upload {
  opacity: 0;
  position: absolute;
  top: 16px;
  left: 202px;
}

.file-upload-input {
  height: 114px;
  width: 114px;
}

.file-name {
  /* text-align: center; */
  text-transform: capitalize;
}

.file-name > div {
  color: #333333;
  font-size: 14px;
}
.selected-file {
  /* width: 160px; */
  width: 85%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.css-1dhitr2 {
  padding-right: 15px !important;
  padding-left: 28px !important;
}

.css-1ji7omv-MuiBackdrop-root {
  z-index: 10000 !important;
}

.visible-icon {
  transform: translateX(-15px);
}

.sidebar-main > li:last-child img {
  transform: translateX(-8px);
  width: 21px !important;
}

.permission-list {
  width: 500px !important;
}

.permission-list > div {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 100% !important;
}

.dynamic-action {
  transform: translateX(-70px);
}

/* .my-class {
  position: sticky;
  top: 60px;
  background: #fff;
  z-index: 10;
} */

@media (min-width: 1440px) {
  .app-container {
    height: 100%;
    width: calc(100% - 100px);
    margin-left: auto;
  }
}

.table-row-grey {
  background-color: #f3f3f3;
  height: 10px;
}

.table-row-white {
  background-color: #ffffff;
}

table td,
table th {
  padding: 2px 0 !important;
  font-size: 14px !important;
}

thead {
  background-color: #cecece;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.action-button {
  padding: 0.5rem 2rem !important;
  font-family: "Montserrat", sans-serif !important;
}

.montserrat-16-600 {
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.montserrat-14-500 {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.montserrat-14-400 {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.multi-select-dropdown input {
  border: none !important;
}
.label-gap {
  padding-bottom: 0.25rem;
}

.label-gap-2 {
  padding-bottom: 0.4rem;
}
/*
.input-border {
  border: 2px solid #b8e7ff;
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px;
  font-weight: 400;
} */

.modal-footer {
  padding: 1rem;
  display: flex;
  justify-content: flex-end !important;
}

/* input[type="text"],
input[type="number"],
input[type="tel"],
input[type="email"] {
  border: 1px solid #b8e7ff !important;
} */

input[type="text"]:disabled,
input[type="numbers"]:disabled,
input[type="tel"]:disabled,
input[type="email"]:disabled {
  border: 1px solid #dddddd !important;
}
input[type="numbers"]:disabled {
  border: none !important;
}
select,
option {
  border: 1px solid #b8e7ff !important;
}

select:disabled,
option:disabled {
  border: 1px solid #dddddd !important;
}

.roles-dropdown {
  margin-top: 0px !important;
  height: 37.5px;
  border: 1px solid #b8e7ff;
  outline: none;
}

.roles-dropdown-disabled {
  height: 37px;
  border-color: #dddddd !important;
  border-width: 1px solid !important;
  outline: none;
}

/* .roles-dropdown input:disabled {
  background-color: red;
} */

.css-13cymwt-control {
  border: 0.5px solid #b8e7ff !important;
  outline: none !important;
  border-radius: 5px !important;
}

.css-3iigni-container {
  border: 0.5px solid #dddddd !important;
}

.css-qbdosj-Input input {
  border: none !important;
}

.searchable-dropdown .MuiInputBase-root {
  border: 0.5px solid #b8e7ff !important;
  padding: 6.5px !important;
}

.disable-searchable-dropdown .MuiInputBase-root {
  border: none !important;
  /* padding: 6.5px !important; */
}

.table-search-box input {
  border: none !important;
}

.currency-text-box > div {
  border: 0.5px solid #b8e7ff !important;
  height: 40px !important;
}

.currency-text fieldset {
  border: none !important;
}

.currency-text .Mui-disabled {
  border: 1px solid transparent !important;
}

/* .tag-input .rti--container input {
  height: 100% !important;
  width: 100% !important;
  padding: 0 10px;
}

.tag-input .rti--container {
  padding: 0 !important;
  height: 40px;
  border: 1px !important;
} */

.tag-input {
  background-color: #f8fcff;
}

.tag-input-search-keyword {
  transform: translateX(-1px) !important;
}

.tag-input label {
  border: 1px solid transparent !important;
}

.rti--container {
  border: 0.5px solid #b8e7ff !important;
}

.rti--container.disabled {
  border: 1px solid transparent !important;
}

.rti--container.disabled input {
  border: none !important;
}

.rti--container input {
  border: none !important;
  width: 100%;
  height: 100%;
}

.css-m94tiq-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
  color: #1aaefa !important;
}

.MuiButton-contained.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.MuiButton-contained {
  background-color: #1aaefa !important;
}

.MuiButton-outlined {
  border: 1px solid #1aaefa !important;
  color: #1aaefa !important;
}

.sort-icon {
  margin-left: 2px;
}

.table-column {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

/* .css-sghohy-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
} */

.choose-file {
  position: absolute;
  top: -28px;
  right: -31px;
  font-size: 16px;
  font-weight: 500;
  color: #1aaefa !important;
}

.content-tabs {
  background: #f4f4f4;
  padding: 0px 15px !important;
}
.content-sub-tabs {
  text-transform: none !important;
  font-size: 16px !important;
}

.content-tabs .Mui-selected {
  color: #333333 !important;
  border-bottom: 1px solid #333333 !important;
}

.content-tabs .MuiTabs-indicator {
  background-color: #333333 !important;
}

.roles-dropdown fieldset {
  border: none !important;
}

/* .searchable-dropdown .MuiInputBase-input {
  border: none !important;
} */

#enterpriseGroupIdDropdown,
#DistributorIDEnterpriseID,
#searchSubscriber {
  border: none !important;
  padding-right: 22px;
  padding-left: 5px;
}

.subscriber-serachable-dropdown .MuiAutocomplete-root .MuiInputBase-root {
  border: 1px solid #b8e7ff !important;
}

.subscriber-serachable-dropdown fieldset {
  border: none !important;
}

/* .MuiInputBase-adornedEnd {
  border: 0.5px solid #b8e7ff !important;
} */

.desktop-date-picker fieldset {
  border: none !important;
}

.subscriber-serachable-dropdown input {
  border: 0.5px solid #b8e7ff !important;
}

.disable-searchable-dropdown #DistributorIDEnterpriseID {
  transform: translateY(-10px);
}

.desktop-date-picker .Mui-disabled {
  border: 1px solid transparent !important;
}

.my-serachable-dropdown input {
  border: none !important;
  transform: translateY(2px);
}

.my-serachable-dropdown .MuiInputBase-root {
  border: 1px solid #b8e7ff !important;
}

.my-serachable-dropdown fieldset,
.search-dropdown fieldset,
.search-dropdown-existing fieldset {
  border: none !important;
}

.search-dropdown .MuiInputBase-root {
  border: 1px solid #b8e7ff !important;
}

.search-dropdown-existing .MuiInputBase-root {
  border: 1px solid #b8e7ff !important;
}

.search-dropdown-existing .Mui-disabled,
.my-serachable-dropdown .Mui-disabled,
.search-dropdown .Mui-disabled {
  border: 1px solid transparent !important;
}
.breadcrumbs h5 {
  font-size: 22px !important;
  font-weight: bold !important;
}

.breadcrumb-title {
  font-size: 15px !important;
  color: #000 !important;
}

.MuiBreadcrumbs-separator {
  font-size: 24px;
  margin-bottom: 4px;
}
.view-button,
.edit-button {
  cursor: pointer;
}

.Mui-checked {
  color: #1aaefa !important;
}

.multi-select-dropdown > div:hover {
  border: 1px solid #b8e7ff !important;
}

#areas input,
#distributorType input,
#carrierRepresents input,
#industryGroups input,
#technologies input,
#typeOfFormList input,
#lineOfBusinessList input,
#subscriptionFrequency input {
  border: none !important;
}

.subscriber-searchable-dropdown .MuiAutocomplete-input {
  min-width: 220px !important;
}
.css-cus-css .disable-searchable-dropdown #DistributorIDEnterpriseID,
.css-cus-css .searchable-dropdown #DistributorIDEnterpriseID {
  transform: translateY(0px);
  border: 1px solid #1aaefa;
}
.css-cus-css {
  width: 33.33%;
}
.css-cus-css .disable-searchable-dropdown {
  width: 100%;
  border: 1px solid #d4d5d6;
  font-size: 8px;
}
.css-cus-css .disable-searchable-dropdown > div > div {
  padding: 0;
}
.css-cus-css .searchable-dropdown > div > div {
  padding: 0;
}
.css-cus-css .searchable-dropdown {
  width: 100%;
}
.accordionWrapper .accDetails {
  padding: 8px 0 16px 16px;
}
.associate-subscribers {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}
.associate-subscribers button {
  margin: 10px 10px 5px 10px;
}
.manage-subscriber-body {
  overflow-y: visible !important;
  height: auto !important;
}
.multiple-associate-subscriber-dropdown
  .MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
  height: auto !important;
  min-height: 50px !important;
  width: auto !important;
  overflow-y: auto;
  max-height: 150px;
}
.custom-input-box input {
  padding: 6px 25px 6px 6px !important;
  border-radius: 3px 0 0 4px;
  border: none !important;
}
.custom-ent-box input {
  padding: 8px 33px 5px 5px !important;
}

#custom-signin {
  margin: 10px 50px;
  padding: 10px 42px;
}

.custom-auth-content {
  display: flex;
  flex-direction: column;
}

.custom-fieldset label.Mui-disabled,
.custom-fieldset span.Mui-disabled,
.custom-fieldset .MuiIconButton-root.Mui-disabled {
  background-color: transparent !important;
}

.custom-fieldset #sign-in-id.MuiButton-contained.Mui-disabled,
.custom-fieldset #sign-in-id:disabled {
  background: none !important;
  border: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  box-shadow: none !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.custom-fieldset {
  height: auto;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.custom-fieldset > label {
  color: #777;
  padding: 7px 10px 7px 0;
  margin-top: 10px;
  margin-bottom: 0;
}

.custom-fieldset input[type="text"],
.custom-fieldset input[type="password"],
.custom-fieldset input[type="number"] {
  height: 40px;
  width: 100%;
  margin: 0;
  padding: 6px 8px;
  line-height: 16px;
  /* border: none; */
  background: transparent;
  box-sizing: border-box;
  /* border: 1px solid #b8e7ff !important; */
  background-color: #fff;
  border-color: #bbb;
  font-size: 15px;
  font-family: "montserrat", Arial, Helvetica, sans-serif;
  overflow: visible;
}

.custom-fieldset input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
  border-radius: 3px;
}

.custom-fieldset input[type="text"]:focus,
.custom-fieldset input[type="password"]:focus {
  box-shadow: inset 0 -1px 0 #ddd;
}

.custom-fieldset input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: -internal-light-dark(
    rgb(232, 240, 254),
    rgba(70, 90, 126, 0.4)
  ) !important;
}

.custom-fieldset .sign-in,
.custom-fieldset #sign-in-id {
  font-family: "montserrat", Arial, Helvetica, sans-serif;
  /* border: none !important; */
  /* border: 1px solid #1aaefa !important; */
  color: #fff;
  cursor: pointer;
  height: 40px !important;
  border-radius: 3px !important;
  background: linear-gradient(#007dc1, #0073b2) !important;
  background-color: #1aaefa !important;
  /* border-color: #004b75 !important; */
  border-bottom-color: #00456a !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0 1px 0,
    rgba(255, 255, 255, 0.1) 0 1px 0 0 inset !important;
  font-size: 15px !important;
  letter-spacing: 1px !important;
}

.custom-fieldset .sign-in:hover,
.custom-fieldset #sign-in-id:hover {
  box-shadow: none;
  background-image: linear-gradient(#0087d0, #007dc1);
  background-color: #0087d0;
  /* border-color: #003d5e; */
  border-bottom-color: #003a59;
  text-decoration: none;
  cursor: pointer;
}

.remember-me > .MuiCheckbox-root {
  margin-left: 0 !important;
}

.remember-me > .MuiFormControlLabel-label {
  font-size: 14px;
}

label.remember-me {
  position: relative;
  right: 10px;
}

.custom-links {
  margin-top: 10px;
}

.custom-links > .clink {
  cursor: pointer;
  font-size: 14px;
  color: #777;
}

.custom-links > .clink:hover {
  text-decoration: underline !important;
}

.custom-fieldset .MuiInputBase-adornedEnd {
  /* border: 1px solid #b8e7ff !important; */
  background-color: transparent !important;
}

.resend-otp {
  color: #0075b5;
  cursor: pointer;
}

.resend-otp:hover {
  text-decoration: underline;
}

.otp-timer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.error-login {
  color: red;
  text-align: center;
  font-size: 20px;
}

.custom-logout-css {
  display: flex;
  align-items: center;
  padding-right: 25px;
  text-decoration: none;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
}

.custom-logout-css img {
  margin-right: 10px !important;
  width: 15px;
}

.mass-upload-content {
  flex-direction: column;
}

.roles-popup-modal-body {
  position: relative;
  overflow-y: hidden !important;
  height: 500px !important;
}

.fix-header-roles-popup {
  position: fixed;
  width: 100% !important;
  padding-right: 20px;
  background-color: #fff;
  z-index: 999;
  height: 150px !important;
  margin-left: -34px !important;
  padding-left: 18px !important;
  padding-right: 32px !important;
}

.fix-header-roles-popup-table-outer {
  position: relative;
  width: 100% !important;
  top: 18px;
  margin-left: 0 !important;
}

.roles-popup-table-outer {
  height: 293px;
  overflow-x: unset !important;
  box-shadow: unset !important;
}

.new-roles-table-outer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
  overflow-x: auto;
}

.new-roles-table-outer::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #f5f5f5;
  overflow-x: auto;
}

.new-roles-table-outer::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #818181;
  overflow-x: auto;
}

.fix-header-roles-popup-table-outer > div {
  padding: 0 !important;
}

.roles-popup-table-head {
  background-color: #777;
}

.roles-popup-table-head th {
  background-color: #c1c1c1;
  /* width: 100%; */
}

.tabs-panel-roles-popup {
  position: relative;
  top: 140px;
  height: 450px;
}

.new-roles-table-outer {
  height: 293px !important;
  overflow-y: scroll;
}

.document-upload .MuiInputBase-root label {
  margin: 2px 0;
}
.upload-doc-cus {
  z-index: 0;
}

.show-upload-doc-status-pane {
  margin: 10px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.show-upload-doc-status-pane span {
  font-size: 18px;
  font-weight: 600;
}

.show-upload-doc-status-pane .close-icon {
  cursor: pointer;
  font-weight: 600;
  color: #ff0000;
}

.list-upload-docs {
  padding: 10px 20px !important;
  background-color: #e9e7e9 !important;
  position: relative !important;
  min-height: 75px;
  max-height: 150px;
  width: 100%;
  overflow-y: auto;
  border-radius: 4px;
  scroll-behavior: smooth;
}

.success-icon {
  color: #38e349;
}

.error-icon {
  color: #ff0000;
}

.pending-icon {
  color: #3e3e3c;
}

.list-upload-docs::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
  overflow-x: auto;
}

.list-upload-docs::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #f5f5f5;
  overflow-x: auto;
}

.list-upload-docs::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #818181;
  overflow-x: auto;
}

.list-popup-filter
  .MuiPaper-root
  .MuiPaper-elevation
  .list-popup-filter
  .MuiPaper-rounded
  .MuiPaper-elevation8
  .MuiPopover-paper
  .css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  position: absolute !important;
  min-width: 16px !important;
  min-height: 16px !important;
  max-width: calc(100% - 32px) !important;
  max-height: calc(100% - 32px) !important;
  top: 223px !important;
  left: 104px !important;
}

.businessPhone {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.businessPhone .MuiFormControl-root {
  width: 72%;
}

.businessPhone > input.ext {
  width: 27%;
}

#phoneNumber,
#secondaryPhoneNumber,
#mobile,
#mobile1,
#fax,
#fax1,
#billingPhoneNumber {
  padding: 6px !important;
}

.add {
  padding-left: 8px !important;
  border: 1px solid #dddddd !important;
  border-radius: 4px !important;
}

#userDocumentId td,
#userDocumentId th {
  padding: 12px !important;
}

.download-link {
  cursor: pointer;
  color: #1aaefa;
  text-transform: uppercase;
}

.error-link {
  cursor: pointer;
  color: #fa1a1e;
  text-transform: uppercase;
}

.download-link:hover,
.error-link:hover {
  text-decoration: underline;
}

.refresh-mod {
  display: flex;
  flex-direction: row-reverse;
  cursor: pointer;
  margin-bottom: 10px;
}

.processed-table th,
.processed-table td {
  padding-left: 5px !important;
}

.downloader-request {
  padding: 5px;
  min-height: 250px;
  max-height: 500px;
  overflow-y: auto;
}

.no-data-found {
  padding: 10px !important;
  text-align: center !important;
}

.processed {
  background-color: #000000;
  color: #fff;
  padding: 2px 5px;
  border-radius: 2px;
}

.in-processing {
  background-color: #b39d9e;
  color: #fff;
  padding: 2px 5px;
  border-radius: 2px;
}

#panel1a-header,
#panel2a-header,
#panel3a-header,
#panel4a-header,
#panel5a-header {
  background-color: #f4f4f4 !important;
}

.xfdlViewer-Share {
  height: cal(100vh - 200px);
}

.xfdlViewer-Share .viewer-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.xfdlViewer-Share .download-share {
  display: flex;
  flex-direction: row-reverse;
}

.xfdlViewer-Share .download-share button {
  z-index: 1;
}

.checkbox-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.checkbox-disabled .MuiSvgIcon-root {
  color: grey;
}

.checkbox-disabled .MuiCheckbox-root.Mui-checked {
  color: grey !important;
}

.checkbox-disabled .MuiCheckbox-indeterminate {
  color: grey !important;
}
