nav.ps-menu-root {
  background-color: #1aaefa !important;
  min-height: 100%;
  display: flex;
  justify-content: center;
}

aside.ps-sidebar-root {
  height: 100%;
}

.menu-logo-div {
  padding: 10px 12px;
}
.menu-logo-div-expanded {
  padding: 10px 17px;
}

.menu-logo:hover {
  cursor: pointer;
}

.css-vj11vy.ps-menu-root {
  justify-content: flex-start !important;
}

.css-1wvake5 {
  width: 250px !important;
}

/* .css-1wvake5.ps-collapsed {
  width: 80px !important;
  min-width: 80px !important;
} */

.css-1wvake5.ps-collapsed {
  width: 60px !important;
  min-width: 60px !important;
}

.css-b3iwgq {
  color: #fff !important;
  font-size: 13px;
  font-weight: 400;
}
.css-wx7wi4 {
  margin: -5.9px !important;
}

.css-eh8qic > .ps-menu-button {
  color: #fff !important;
  font-size: 13px !important;
  padding-left: 20px !important;
  display: flex !important;
  height: 42px !important;
}

.css-3hn74v > .ps-menu-button {
  color: #fff !important;
  font-size: 13px !important;
  padding-right: 0 !important;
  padding-left: 20px !important;
  height: 42px !important;
  display: flex !important;
}

.css-1qhe6ly {
  display: none !important;
}

.css-12w9als {
  flex-grow: 0 !important;
  overflow: visible !important;
  display: flex;
  align-items: center;
  margin: 0px;
}

.css-16jkw2k > .ps-menu-button {
  padding-left: 20px !important;
  height: 42px !important;
}

.css-16jkw2k > .ps-menu-button:hover {
  background-color: black !important;
}

.menu-collapsed-btn {
  position: fixed;
  background: #eee5e5;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  /* left: 205px; */
  top: 20px;
  left: 59px;
  transition-duration: 0.3s;
  box-shadow: 9px 1px 26px 2px rgb(165 165 165);
  cursor: pointer;
}

.menu-expanded-btn {
  position: fixed;
  background: #eee5e5;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  left: 250px;
  top: 22px;
  transition-duration: 0.3s;
  box-shadow: 9px 1px 26px 2px rgb(165 165 165);
  cursor: pointer;
}

.ps-submenu-content.ps-active.css-1l5fxv3 .css-ewdv3l .ps-menu-button {
  width: 100% !important;
}

.ps-menuitem-root.ps-active {
  background-color: black;
  width: 100% !important;
}
.ps-menuitem-root.ps-submenu-root.ps-active {
  background-color: black;
}

.ps-menuitem-root a {
  transition-duration: 0.2s;
  width: 100%;
}

/* .ps-submenu-content.css-1l5fxv3 */

.ps-sidebar-root.ps-collapsed.css-1wvake5 .ps-menuitem-root a {
  width: 60px !important;
}

@media (min-width: 1440px) {
  .ps-sidebar-root.ps-collapsed {
    width: 100px !important;
    min-width: 100px !important;
  }

  .ps-submenu-content ul a {
    margin-left: 0 !important;
  }

  .ps-submenu-content,
  .ps-submenu-content.ps-open {
    overflow: hidden !important;
  }

  .my-sidebar aside {
    width: 280px !important;
    min-width: 280px !important;
  }

  .ps-submenu-content {
    overflow: hidden !important;
    background-color: #1aaefa;
    margin-left: 15px !important;
    box-shadow: 0px 0px 0, -15px 0px 0 #1aaefa !important;
  }

  li.ps-menuitem-root > .ps-menu-button:hover {
    background-color: transparent !important;
  }

  .ps-sidebar-root.ps-collapsed .ps-menuitem-root a {
    margin-left: 20px;
  }
  .menu-logo-div img {
    margin-left: 20px;
  }
  .menu-expanded-btn {
    position: fixed;
    background: #eee5e5;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    left: 264px;
    top: 22px;
    transition-duration: 0.3s;
    box-shadow: 9px 1px 26px 2px rgb(165 165 165);
    cursor: pointer;
  }

  .menu-collapsed-btn {
    position: fixed;
    background: #eee5e5;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    top: 20px;
    left: 88px;
    transition-duration: 0.3s;
    box-shadow: 9px 1px 26px 2px rgb(165 165 165);
    cursor: pointer;
  }
  .ps-menu-label {
    overflow: hidden !important;
  }
  .ps-submenu-content ul li:hover {
    background-color: transparent;
  }
  .ps-menuitem-root.ps-active {
    background-color: black;
    width: 100% !important;
  }
}

.ps-sidebar-root.ps-collapsed .ps-submenu-root .ps-menuitem-root a {
  width: 100% !important;
}

.ps-sidebar-root.ps-collapsed .ps-menuitem-root .ps-submenu-content a {
  width: 100% !important;
}

.my-sidebar {
  position: sticky;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 20;
  /* background: #1aaefa; */
}

.ps-menu-label img {
  width: 19px !important;
}

.main-menu-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.admin-css {
  width: 21px !important;
  transform: translateX(-8px);
  margin-right: -10px;
}

.ps-menuitem-root.ps-submenu-root .ps-menu-button:hover {
  background-color: transparent !important;
}

.css-ewdv3l {
  display: flex;
  flex-direction: column;
  background-color: #1aaefa;
}

.css-16jesut > .ps-menu-button {
  display: auto !important;
  padding-left: 19px !important;
  padding-left: 10px;
  height: auto !important;
}

.ps-submenu-content.css-1l5fxv3 {
  transform: translate3d(60.3333px, 413.333px, 0);
}

.view-menu {
  display: none;
}

.view-menu:hover {
  display: block;
}

.css-1097eso {
  visibility: hidden;
}

.ps-menu-label.css-12w9als {
  color: #fff !important;
  font-size: 13px;
}

.setup {
  margin-right: 15px !important;
}

.ps-submenu-content.css-1l5fxv3 .ps-menu-suffix.css-1qhe6ly {
  display: block !important;
  opacity: 1;
  font-size: 13px;
  color: #fff;
}

.css-1cuxlhl {
  margin-left: 111px;
  color: #fff;
}

.css-dip3t8 {
  overflow-y: auto !important;
}
.version-text {
  color: white;
  font-size: 15px !important;
  padding-left: 42px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
