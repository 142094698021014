.internal-users-container {
  border: 1px red solid;
  width: 100%; /* falllback */
  display: flex;
}
.internal-users-container > .table-content {
  width: 100%;
}

.action-items {
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-action {
  margin-right: 20px;
  cursor: pointer;
}

.admin-table td,
.admin-table th {
  padding: 12px 0;
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif !important;
}

tbody tr td:last-child svg {
  width: 20px;
  cursor: pointer;
}

.table-search-box input {
  padding: 5px !important;
}

.welcome-main {
  display: flex;
  margin: 20px 0;
  align-items: center;
  justify-content: space-between;
}

td h5 {
  font-size: 14px !important;
  font-weight: 500;
  font-family: "Montserrat", sans-serif !important;
}

td p {
  font-size: 12px !important;
  font-weight: 500;
  font-family: "Montserrat", sans-serif !important;
}

/* .action-btn {
  margin-right: 2rem !important;
} */

#modal-modal-title {
  font-size: larger;
  font-weight: 700;
}
