.btns-share-xfdl {
  position: relative;
  top: -51px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  left: 147px;
  justify-content: flex-end;
}
.btns-share-xfdl-down {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px !important;
  margin-top: 15px !important;
}
.button-mrgin-share {
  margin: 5px !important;
}
.Grid-div-share {
  width: 100% !important;
  display: flex;
  justify-content: center !important;
  padding-bottom: 15px !important;
}
.width-container-share {
  max-width: 1040px !important;
}

.btns-share-xfdl button {
  margin: 10px;
}

.select-share-xfdl {
  position: relative;
  top: -51px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px;
}
.agency-name-align {
  margin-bottom: 6px !important;
  margin-right: 10px !important;
  font-weight: 700 !important;
}
.pdf-div-foldername {
  margin-top: -30px !important;
}
.pdf-div-foldername-label {
  margin-bottom: 20px !important;
}
.blue-field-section {
  background-color: #f8fcff;
}

.top-right-actions {
  display: flex;
  justify-content: flex-end;
}

.add-itemz-font h5 {
  cursor: pointer;
  color: #1aaefa;
  font-size: inherit;
  font-weight: 500;
}
.add-association-share {
  color: #848484;
  font-weight: 600 !important;
  padding-right: 10px !important;
  margin-top: -16px !important;
  top: 0px !important;
}
