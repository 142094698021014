.table-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 5px !important;
}

.admin-table {
  padding: 0 10px;
}

.action-items {
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-action {
  margin-right: 20px;
  cursor: pointer;
}

.roles {
  width: 15rem;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
}

.resp-admin-data-table .admin-data-roles {
  max-width: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.approval-roles-visible {
  max-width: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px !important;
}

.admin-table td,
.admin-table th {
  padding: 12px 0;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  font-family: "Montserrat", sans-serif !important;
  border: none !important;
}

td:first-child,
th:first-child {
  padding-left: 20px !important;
}

tbody tr td:last-child svg {
  width: 20px;
  cursor: pointer;
}

.table-search-box input {
  padding: 5px !important;
}
.table-search-boxx {
  background: #f3f3f3 !important;
}

.welcome-main {
  display: flex;
  margin: 20px 0;
  align-items: center;
  justify-content: space-between;
}

td h5 {
  font-size: 14px !important;
  font-weight: 500;
  font-family: "Montserrat", sans-serif !important;
}

td p {
  font-size: 12px !important;
  font-weight: 500;
  font-family: "Montserrat", sans-serif !important;
}

/* .action-btn {
  margin-right: 2rem !important;
} */

#modal-modal-title {
  font-size: larger;
  font-weight: 700;
}

.styled-basic-cell-rejected {
  padding: 0.25rem 1rem;
  border-radius: 3rem;
  background-color: #e14949;
  color: white;
}

.styled-basic-cell-pending {
  padding: 0.25rem 1rem;
  border-radius: 3rem;
  background-color: #ee913b;
  color: white;
}

.styled-basic-cell-approved {
  padding: 0.25rem 1rem;
  border-radius: 3rem;
  background-color: #25ce73;
  color: white;
}
.table-container {
  overflow-x: auto !important;
}

.sticky-col {
  position: sticky !important;
  background: inherit;
  padding-left: 10px !important;
}
.sticky-header {
  position: sticky !important;
  background: #cecece;
  padding-left: 10px !important;
}
