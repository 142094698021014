.card-item-labels {
  margin-left: 20px !important;
}

.detail-content-s {
  font-weight: 500 !important;
  margin-left: 20px !important;
  max-width: 300px !important;
  overflow: hidden;
}
.brand-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.breadcrumbs h5 {
  font-size: 22px !important;
  font-weight: bold !important;
}
.distributor-breadcrumbs {
  color: black;
}
.add-welcome-widgets-breadcrumb {
  position: absolute;
  display: flex;
  flex-direction: row;
  right: 68px;
  top: 1rem;
}

.add-welcome-widgets-breadcrumb svg {
  margin-left: 11px;
  padding: 2px 4px;
  cursor: pointer;
  border-radius: 2px;
  background-color: #1aaefa;
  color: #fff;
  border: 1px solid transparent;
  height: 30px !important;
  width: 30px !important;
}

.distributor-breadcrumbs > p {
  font-weight: 600;
}

.distributor-breadcrumbs > a {
  color: black !important;
}
.custom-bredscrum-width {
  width: 50%;
}
.custom-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.breadcrumb-title {
  font-size: 15px !important;
  color: #000 !important;
}
.basicinfo {
  width: 100%;
  /* align-items: center;
  justify-content: space-between; */
  background: #fff;
  padding-bottom: 20px;
}
.content-last {
  left: 10px;
  font-size: 15px !important;
  font-weight: bold !important;
}
.fixed-comp {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
}
.csssss {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.cus-brandname-grid {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #fff;
  position: fixed;
  width: 100%;
  margin-top: 37px;
  padding: 20px 0px;
  margin-left: -17px;
  padding-left: 34px;
}
.cus-brandname-grid-new {
  position: absolute !important;
  margin-top: 57px;
  margin-left: -17px;
  padding-left: 34px;
  background-color: #fff;
  padding-top: 20px;
}
.cus-cus-pad-pad {
  position: fixed;
  width: 100%;
  padding-bottom: 40px;
  background-color: #fff;
  margin-left: -17px;
  padding-left: 34px;
  margin-top: 0px;
}

.breadcrumbs-cus-pad {
  padding-top: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  padding-bottom: 0 !important;
  margin-top: -16px;
}
/* .toast-cus-top {
  top: -7px !important;
}
.toast-cus-toast-top {
  margin-top: 0px !important;
} */
.brand-logo {
  height: 60px;
  width: 60px;
}
.add-welcome-widgets-breadcrumb-admin {
  position: absolute;
  display: flex;
  flex-direction: row;
  right: 116px;
}
.add-welcome-widgets-breadcrumb-manage-subscriber {
  position: absolute;
  display: flex;
  flex-direction: row;
  right: 78px;
}
