.distributor-container {
  background-color: #f8fcff;
}

.distributor-breadcrumbs {
  color: black;
}

.distributor-breadcrumbs > p {
  font-weight: 600;
}

.distributor-breadcrumbs > a {
  color: black !important;
}
.position-fix-panel {
  margin-top: 100px !important;
}

.right-section {
  background-color: #fff;
}
.distributor-profile {
  padding: 0 10px;
}

.add-expanded-filter {
  width: 300px;
  background-color: #fff;
  z-index: 10;
  box-shadow: 0px 5px 20px rgb(69 69 69 / 10%);
  border-radius: 3px;
  padding: 20px;
}

.add-welcome-widgets {
  position: absolute;
  text-align: end;
  display: flex;
  top: 5rem;
}

.add-welcome-widgets svg {
  margin-left: 9px;
  padding: 2px 4px;
  cursor: pointer;
  border-radius: 2px;
  background-color: #1aaefa;
  color: #fff;
  border: 1px solid transparent;
  height: 30px !important;
  width: 30px !important;
}

.card-bg {
  background-color: #333333;
  height: 60px;
  width: 100%;
}

.my-card {
  position: relative;
}

.card-image {
  position: absolute;
  top: 15px;
  left: 20px;
}

.card-image img {
  height: 70px;
  width: 70px;
}

.card-content-heading {
  font-size: 10px !important;
  font-weight: 500 !important;
  color: rgba(51, 51, 51, 0.3);
  margin-bottom: 0 !important;
}

.card-content-heading-answer {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #333333;
}

.application-status {
  position: absolute;
  top: 18px;
  right: 14px;
  background: #fef1d0;
  font-size: 10px;
  font-weight: 500;
  padding: 1px 9px;
  border-radius: 10px;
}

/* .tabs button {
    padding: 0 !important;
} */

.tabs .PrivateTabIndicator-root-1,
.tabs .PrivateTabIndicator-colorSecondary-7 {
  background-color: #1aaefa !important;
}

.tabs .MuiTab-wrapper {
  font-size: 14px;
  font-weight: 500;
}

.tabs .MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit.Mui-selected {
  color: #1aaefa !important;
}

.tabs .MuiTabs-flexContainer {
  max-width: 650px;
}

.internal-user-tab .admin-table {
  padding: 0;
}

.tab-modal-body {
  max-height: 320px !important;
}

.basic-information-tab {
  padding: 0 !important;
}

.internal-user-tab-main {
  /* padding: 10px !important; */
}

.card-header {
  padding: 0;
  border: #fff 0px solid;
  position: relative;
}
.card-header > .card-subheader1 {
  background-color: #000;
  padding: 0;
  min-height: 3rem;
  position: relative;
}

.card-header > .card-subheader2 {
  background-color: #fff;
  padding: 0;
  min-height: 3rem;
  border: #fff 0px solid;
}

.card-header > .big-avtar {
  background-color: #fff;
  position: absolute;
  top: 1rem;
  left: 1rem;
  height: 4rem;
  width: 4rem;
}

.card-content {
  padding: 1rem;
  padding-top: 0;
}

.card-content > .card-item {
  margin: 0.5rem 0;
}

.card-item-label {
  margin-left: 20px !important;
}

.card-content > .card-item > .card-item-value {
  font-weight: 500 !important;
}

.MuiCardHeader-content > .black-div {
  background-color: #333;
  height: 2rem;
}
.detail-content {
  font-weight: 500 !important;
  margin-left: 20px !important;
  max-width: 300px !important;
  overflow: hidden;
}
.detail-content-email {
  font-weight: 500 !important;
  margin-left: 20px !important;
  max-width: 300px !important;
  overflow: hidden;
  line-break: anywhere !important;
}
.MuiCardHeader-content > .white-div {
  background-color: #000;
  height: 2rem;
}

@media (max-width: 2680px) {
  .distributor-profile-form {
    margin-left: 20px !important;
    width: 100%;
  }
}
.custom-marginleft {
  margin-left: 0 !important;
}
.custom-width {
  width: calc(100% - 22px) !important;
}
.custom-width-box {
  max-width: 100% !important;
  flex-basis: 100% !important;
}
.custom-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.custom-bredscrum-width {
  width: 50%;
}

.distributor-tab-panel {
  /* height: 478px; */
  height: calc(100vh - 280px) !important;
  overflow: auto;
}

.distributor-tab-panel::-webkit-scrollbar {
  width: 11px;
  border-radius: 10px !important;
}

/* Track */
.distributor-tab-panel::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* Handle */
.distributor-tab-panel::-webkit-scrollbar-thumb {
  background: #b0adad;
  border-radius: 10px !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* Handle on hover */
.distributor-tab-panel::-webkit-scrollbar-thumb:hover {
  background: #b0adad;
  border-radius: 10px !important;
}
