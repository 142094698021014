.modal-header {
  padding: 5px 15px !important;
}

input {
  width: 100%;
}

.modal-body-cus label {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
}
.modal-body-cus {
  height: 500px;
  overflow-y: scroll;
}

.modal-body-cus::-webkit-scrollbar {
  width: 10px;
  border-radius: 8px;
}

/* Track */
.modal-body-cus::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.modal-body-cus::-webkit-scrollbar-thumb {
  background: #a2a1a1;
  border-radius: 8px;
}

/* Handle on hover */
.modal-body-cus::-webkit-scrollbar-thumb:hover {
  background: #636262;
}

.modal-body label {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
}

h5 {
  margin-bottom: 0;
}

.add-item h5 {
  margin-top: 31px;
  cursor: pointer;
  color: #1aaefa;
  font-size: 14px;
  font-weight: 500;
}

.modal-footer button:first-child {
  margin-right: 20px;
  font-size: 14px;
}

.modal-footer button:last-child:hover {
  box-shadow: 0 5px 10px rgba(26, 174, 250, 0.5);
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
option.form-control,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="select"],
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
}

input::placeholder {
  font-size: 13px !important;
  font-weight: 500;
}

.hide-switch {
  height: 15px;
  width: 25px;
  background: transparent;
  position: absolute;
  z-index: 20;
  bottom: 50px;
  left: 25px;
}

.select-user {
  font-size: 14px;
}

.primary-email-url {
  display: block;
  margin-top: 4px;
  word-break: break-all;
  font-size: 14px;
}

.custom-modal-content {
  width: 80% !important;
  height: 80% !important;
}

.custom-modal-content .modal-body {
  height: 90% !important;
}

.action-items {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: auto;
  margin: 5px;
}

.action-items button {
  margin: 0 10px;
}
.add-welcome-widgetsfilter-cus-filter-icon {
  text-align: end;
  display: flex;
  top: 5rem;
  cursor: pointer;
  border-radius: 2px;
  background-color: #1aaefa;
  color: #fff;
  border: 1px solid transparent;
  padding: 3.9px 4.2px;
  margin-top: 0.2px !important;
}
.cancel-button-align {
  margin-left: 0px !important;
  height: 33.5px !important;
}
.add-button-align {
  height: 33.5px !important;
}
.add-disabled-button {
  height: 33.5px !important;
}
