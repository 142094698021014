.cus-head-mod {
  font-weight: 700 !important;
}
.cus-ti-mod {
  overflow: scroll !important;
}
.distributor-tab-panel-services {
  /* height: 478px; */
  height: calc(100vh - 280px) !important;
  /* overflow: auto; */
}

.distributor-tab-panel-services::-webkit-scrollbar {
  width: 11px;
  border-radius: 10px !important;
}

/* Track */
.distributor-tab-panel-services::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* Handle */
.distributor-tab-panel-services::-webkit-scrollbar-thumb {
  background: #b0adad;
  border-radius: 10px !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* Handle on hover */
.distributor-tab-panel-services::-webkit-scrollbar-thumb:hover {
  background: #b0adad;
  border-radius: 10px !important;
}

.right-section-vendor {
  background-color: #fff !important;
  width: 106% !important;
}
.breadcrumb-title-intsolution {
  font-size: 15px !important;
  color: #000 !important;
  font-weight: bold !important;
}
