.search-text-field input {
  padding: 5px !important;
  /* z-index: 11; */
}

.global-search {
  visibility: hidden;
}

.search-text-field input::placeholder {
  font-size: 14px;
}

.search-text-field div {
  z-index: 11;
}

.search-icon-div img {
  padding-right: 5px;
  width: 18px;
}

.profile-photo {
  height: 40px;
  width: 40px;
}

.profile-photo:hover {
  cursor: pointer;
}

.profile-photo > .profile {
  background-color: lightgray;
  padding: 0.5rem;
  margin: 0.25rem 0;
  border-radius: 50%;
}

.profile-photo > .brandImage {
  height: 40px;
  width: 40px;
  padding: 0 !important;
}

.notification-profile {
  height: 60px;
  /* height: 40px; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 0 10px;
  box-shadow: 0px 0px 30px rgba(69, 69, 69, 0.1);
  position: relative;
}

.notification-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-55%, -50%);
  white-space: nowrap;
}

.testmessagecustext {
  font-size: 22px !important;
  font-weight: bold !important;
  color: #d32f2f !important;
}

.notification {
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-bell {
  margin-right: 20px;
  padding: 3px 8px;
  border-radius: 5px;
  display: none;
}

.notification-bell:hover {
  background-color: #000;
}

.notification-bell img {
  position: relative;
}

.notification-bar {
  position: absolute;
  right: 83px;
  top: 46px;
  max-width: 350px;
  width: 100%;
  background-color: #fff;
  max-height: 300px;
  padding: 10px;
  z-index: 10;
  -webkit-box-shadow: 3px 2px 8px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 2px 8px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 2px 8px -3px rgba(0, 0, 0, 0.75);
}

.notification-bar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notification-bar-main {
  display: flex;
  align-items: center;
}

.expanded-search {
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  z-index: 10;
  display: none !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 20px 20px 20px;
  box-shadow: 0px 20px 15px rgba(69, 69, 69, 0.2);
}

.expanded-right,
.expanded-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.expanded-right {
  align-items: flex-start;
}

.search-text-field input:focus + .expanded-search {
  /* display: block; */
}

.expanded-left h6 {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 0;
}

.expanded-left p {
  font-size: 12px;
  font-weight: 500;
}

.expanded-right ul {
  font-size: 12px;
  margin-bottom: 0;
  list-style: none;
}

.expanded-right ul li a {
  color: #333333;
  font-weight: 400;
}

.expanded-right ul li a:hover {
  color: #1aaefa;
}

.expanded-right h5 {
  font-size: 14px;
  margin-bottom: 10px;
}

.people-list,
.role-list {
  margin-right: 50px;
  position: relative;
}

.tips-list {
  position: relative;
}

.tips-data img {
  width: 14px;
  margin-right: 5px;
}

.tips-data h5 {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
  max-width: 160px;
}

.people-list svg,
.role-list svg,
.tips-list svg {
  position: absolute;
  left: -19px;
  top: -3px;
  width: 15px;
  color: #1aaefa;
}

.cross-button {
  position: absolute;
  top: 20px;
  right: 20px;
}

.cross-button svg {
  width: 20px;
}

.cross-button svg:hover {
  background-color: #333333;
  color: #fff;
  border-radius: 2px;
  /* padding: 1px 5px; */
}

.logout-menu {
  padding: 5px 8px;
  background-color: #333333;
  max-width: 160px;
}
.select-cus-roles {
  background-color: #333333;
  color: #fff;
}

.role-info {
  color: white;
  font-size: 11px;
  width: 140px;
  margin-right: 2px !important;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.logout-menu ul {
  margin-bottom: 0;
}

.logout-menu ul li {
  margin: 7px 0;
  list-style: none;
}

.logout-menu img {
  margin-right: 30px;
  width: 15px;
}

.logout-menu a {
  display: flex;
  align-items: center;
  padding-right: 25px;
  text-decoration: none;
  color: #fff;
  font-size: 12px;
}

.logout-menu a img {
  margin-right: 10px;
}

.settings {
  margin-right: 10px;
}

.header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.user-role-custom {
  padding-right: 10px !important;
  padding-top: 5px !important;
  font-weight: 500 !important;
}
