.reports-container {
  background-color: #f8fcff;
}
.reports-error-message {
  font-size: 12px !important;
  margin-top: -8px !important;
}
.reports-message-typography {
  color: rgba(66, 36, 236, 0.904);
  font-size: 10px;
  font-weight: 200 !important;
  margin-top: -25px !important;
}
